import defaults from '../defaults';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const connectDevTools = (storeState: any, config: any) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const devTools = window.__REDUX_DEVTOOLS_EXTENSION__.connect({
		name: `Store ${storeState.key}`,
		serialize: true,
		trace: true,
		...config,
	});
	devTools.init(storeState.getState());
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	devTools.subscribe((message: any) => {
		if (message.type === 'DISPATCH') {
			// eslint-disable-next-line default-case
			switch (message.payload.type) {
				case 'RESET':
					storeState.resetState();
					devTools.init(storeState.getState());
					return;
				case 'COMMIT':
					devTools.init(storeState.getState());
					return;
				case 'ROLLBACK':
					storeState.setState(JSON.parse(message.state));
					devTools.init(storeState.getState());
					return;
				case 'JUMP_TO_STATE':
				case 'JUMP_TO_ACTION':
					storeState.setState(JSON.parse(message.state));
			}
		} else if (message.type === 'ACTION') {
			const action = JSON.parse(message.payload);
			storeState.setState(action.payload);
		}
	});
	return devTools;
};

const withDevtools =
	<T extends (...params: any[]) => any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		createStoreState: T,
	) =>
	(...args: Parameters<T>): ReturnType<T> => {
		const storeState = createStoreState(...args);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (defaults.devtools && window && window.__REDUX_DEVTOOLS_EXTENSION__) {
			const origMutator = storeState.mutator;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			let devTools: any;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const devtoolMutator = (arg: any) => {
				const result = origMutator(arg);
				try {
					if (!devTools) {
						const config =
							typeof defaults.devtools === 'function' ? defaults.devtools(storeState) : {};
						devTools = connectDevTools(storeState, config);
					}
					devTools.send(
						{ type: storeState.mutator.actionName, payload: arg },
						storeState.getState(),
						{},
						storeState.key,
					);
				} catch (err) {
					/* ignore devtools errors */
				}
				return result;
			};
			storeState.mutator = devtoolMutator;
		}

		return storeState;
	};

export default withDevtools;
