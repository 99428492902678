import type { DefaultFeatureFlagValue, FeatureFlagValue } from '../../common/types';
import getAllFeatureFlags from '../get-all-feature-flags';
import { parseFeatureFlagValue } from '../parse-feature-flag-value';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { FeatureFlagValue } from '../../common/types';

/**
 * @deprecated Use `expVal` or `fg` instead - see jira/docs/playbook/statsig.md for more details.
 *
 * @param defaultValue boolean 'true' value is not allowed go/ff-default
 */
export function getFeatureFlagValue<T extends FeatureFlagValue>(
	featureFlagName: string,
	defaultValue: T extends DefaultFeatureFlagValue ? T : never,
): T {
	return parseFeatureFlagValue(getAllFeatureFlags(), featureFlagName, defaultValue);
}

/**
 * @param defaultValue No other values allowed other than false go/ff-default
 */
export const ff = (featureFlagName: string, defaultValue: false = false): boolean => {
	const flagValue = getFeatureFlagValue(featureFlagName, defaultValue);

	const isValid = typeof flagValue === 'boolean';

	return isValid ? flagValue : defaultValue;
};

/**
 * @deprecated Use `expVal` instead - see jira/docs/playbook/statsig.md for more details.
 */
export const getMultivariateFeatureFlag = <T extends string>(
	featureFlagName: string,
	defaultValue: T,
	possibleValues: Readonly<T[]>,
): T => {
	const flagValue = getFeatureFlagValue<T>(
		featureFlagName,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		defaultValue as T extends DefaultFeatureFlagValue ? T : never,
	);

	const isValid = typeof flagValue === 'string' && possibleValues.includes(flagValue);

	return isValid ? flagValue : defaultValue;
};
