import React, { type ReactNode } from 'react';
import type { RouterContext } from '../../../common/types.tsx';
import type {
	RouteResource,
	RouteResourceResponse,
	RouteResourceUpdater,
} from '../../common/types';
import { useResource } from '../use-resource';

type Props<T> = {
	children: (
		resource: RouteResourceResponse<T> & {
			update: (getNewData: RouteResourceUpdater<T>) => void;
			refresh: () => void;
		},
	) => ReactNode;
	resource: RouteResource<T>;
	options?: {
		routerContext?: RouterContext;
	};
};

export const ResourceSubscriber = <T,>({ children, resource, options }: Props<T>) => {
	const result = useResource(resource, options);

	return <>{children(result)}</>;
};
