import { qs } from 'url-parse';
import type {
	InvariantRoutes,
	MatchedRoute,
	MatchedInvariantRoute,
	Query,
	Routes,
	Route,
	InvariantRoute,
} from '../../types';
import execRouteMatching from './exec-route-matching';
import { matchRouteCache } from './utils';

/**
 * Does the given `pathname` and `queryStr` match a route in `routes`.
 *
 * Heavily based on https://github.com/ReactTraining/react-router/blob/master/packages/react-router-config/modules/matchRoute.js
 *
 * Note: This does not support nested routes at this stage.
 */
const matchRoute = <T extends Route | InvariantRoute>(
	routes: T[],
	pathname: string,
	queryParams: Query = {},
	basePath = '',
) => {
	const queryParamObject =
		typeof queryParams === 'string' ? (qs.parse(queryParams) as Query) : queryParams;

	const cachedMatch = matchRouteCache.get<T>(pathname, queryParamObject, basePath);
	if (cachedMatch && routes.includes(cachedMatch.route)) return cachedMatch;

	for (let i = 0; i < routes.length; i++) {
		const matchedRoute = execRouteMatching(routes[i], pathname, queryParamObject, basePath);
		if (matchedRoute) {
			// Deferred resources experiment
			if ('lazyOpts' in matchedRoute.route && typeof matchedRoute.route.lazyOpts === 'function') {
				if (process.env.BUILD_VARIANT !== 'route-experiment') {
					throw new Error(
						'Lazy evaluation of route options is only available on the `route-experiment` variant',
					);
				}
				const opts = matchedRoute.route.lazyOpts();
				// Apply the resource de-duping from src/packages/routes/src/ui/spa/index.tsx
				if (Array.isArray(opts.resources)) {
					opts.resources = [...new Set(opts.resources)];
				}

				for (const key of Object.keys(opts)) {
					// Trying to avoid replacing the route for the experiment, as I'm not sure if there's an object
					// identity shennanigans going on. It's _probably_ safe, and this can be made typesafe with
					//
					// routes[i] = {...route, ...opts};
					//
					// .. but I'll leave this for the experiment and re-visit if/when we do it "for real"
					//
					// @ts-expect-error I'm not sure how to make this typesafe..
					matchedRoute.route[key] = opts[key];
				}
				delete matchedRoute.route.lazyOpts;
			}
			matchRouteCache.set(pathname, queryParamObject, basePath, matchedRoute);

			return matchedRoute;
		}
	}

	return null;
};

export const matchInvariantRoute = (
	routes: InvariantRoutes,
	pathname: string,
	queryParams: Query | undefined,
	basePath = '',
): MatchedInvariantRoute | null => matchRoute(routes, pathname, queryParams, basePath);

/**
 * Performance optimisation to fast-match a single route
 * instead of looping thorugh all defined routes
 */
export const warmupMatchRouteCache = (
	route: Route,
	pathname: string,
	queryParams: Query | undefined,
	basePath = '',
) => {
	matchRoute([route], pathname, queryParams, basePath);
};

const defaultMatchRoute = (
	routes: Routes,
	pathname: string,
	queryParams: Query | undefined,
	basePath = '',
): MatchedRoute | null => matchRoute(routes, pathname, queryParams, basePath);

export default defaultMatchRoute;
