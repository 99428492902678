import { createContext } from 'react';
import { defaultRegistry } from './store/registry.tsx';
import type { Store, ActionThunk } from './types';

// calculateChangedBit param has been removed
// as it's no longer supported in React 18
export const Context = createContext({
	globalRegistry: defaultRegistry,
	retrieveStore: <TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
		Store: Store<TState, TActions>,
	) => defaultRegistry.getStore(Store),
});
