// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { createBrowserHistory, createMemoryHistory } from 'history';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { createRouterSelector } from './controllers/router-store/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { MemoryRouter, Router } from './controllers/router/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { Redirect } from './controllers/redirect/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { RouterActions } from './controllers/router-actions/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { RouterSubscriber } from './controllers/router-subscriber/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { usePathParam } from './controllers/use-path-param/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useQueryParam } from './controllers/use-query-param/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useRouter } from './controllers/use-router/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useRouterActions } from './controllers/use-router-actions/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { withRouter } from './controllers/with-router/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { RouteComponent } from './ui/route-component/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as Link } from './ui/link/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { createLegacyHistory } from './common/utils/create-legacy-history/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { createRouterContext, findRouterContext } from './common/utils/router-context/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { generatePath } from './common/utils/generate-path/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as matchRoute } from './common/utils/match-route/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { invokePluginLoad } from './controllers/plugins';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	AdditionalRouteAttributes,
	BrowserHistory,
	CreateRouterContextOptions,
	FindRouterContextOptions,
	GenerateLocationOptions,
	History,
	HistoryAction,
	HistoryActions,
	HistoryBlocker,
	HistoryListen,
	LinkProps,
	Location,
	LocationShape,
	Match,
	MatchParams,
	MatchedInvariantRoute,
	MatchedRoute,
	Query,
	Route,
	Routes,
	RouteContext,
	RouterContext,
	Plugin,
	ShouldReloadFunction,
} from './common/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	RouterActionsType,
	RouterActionPush,
	RouterActionReplace,
	RouterSubscriberProps,
} from './controllers/router-store/types';

// extra exports for resources only
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	RouterStore,
	useRouterStoreActions,
	getRouterState,
	useIsActiveRoute,
} from './controllers/router-store';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { EntireRouterState, AllRouterActions } from './controllers/router-store/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DEFAULT_MATCH, DEFAULT_ROUTE } from './common/constants';

// re-export resources entry-point to keep it backwards compatible with 0.20.x version
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { addResourcesListener } from './resources/controllers/add-resource-listener/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { createResource } from './resources/controllers/resource-store/utils/create-resource/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ResourceDependencyError } from './resources/controllers/resource-store/utils/dependent-resources/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ResourceSubscriber } from './resources/controllers/resource-subscriber/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useResource } from './resources/controllers/use-resource/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	useResourceStoreContext,
	getResourceStore,
	ResourceStore,
} from './resources/controllers/resource-store/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mockRouteResourceResponse } from './resources/common/mocks/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	RouteResources,
	ResourceStoreContext,
	ResourceStoreData,
	RouteResource,
	RouteResourceError,
	RouteResourceLoading,
	RouteResourceResponse,
	RouteResourceUpdater,
	RouterDataContext,
	UseResourceHookResponse,
} from './resources/common/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	CreateResourceArgSync,
	CreateResourceArgAsync,
	CreateResourceArgBase,
} from './resources/controllers/resource-store/utils/create-resource/index.tsx';

// hacks currently used in Jira
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { isServerEnvironment } from './common/utils/is-server-environment/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as matchQuery } from './common/utils/match-route/matchQuery';
