import { useRouterStore } from '../router-store';
import type { RouterActionsType, RouterState } from '../router-store/types';

/**
 * @deprecated
 * Do not use this hook, as it creates performance problems during transitions. Use
 * useIsActiveRoute or subscribers instead.
 *
 * Utility hook for accessing the public router store
 *
 * Note that this should eventually filter out private state / actions
 */
export const useRouter: () => [RouterState, RouterActionsType] = () => useRouterStore();
