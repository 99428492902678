import React from 'react';
// eslint-disable-next-line jira/restricted/react-redux
import { Provider as Provider5 } from 'react-redux';
// eslint-disable-next-line jira/restricted/react-redux
import {
	connect as connect8,
	Provider as Provider8,
	useStore,
	useSelector,
	useDispatch,
	type Connect,
	type ConnectedComponent,
	type ProviderProps as ProviderProps8,
	type ReactReduxContextValue,
} from 'react-redux--next';
import type { AnyAction } from 'redux';
import { withStability } from './redux-stability';
import { mapStateToPropsWithErrorHandling } from './utils';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	Connect,
	ConnectedComponent,
	ProviderProps8 as ProviderProps,
	ReactReduxContextValue,
};
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useStore, useSelector, useDispatch };
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { extractFactoryFunction, mapStateToPropsWithErrorHandling } from './utils';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const connect: Connect = ((
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		mapStateToProps?: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		mapDispatchToProps?: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		mergeProps?: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		options: any = Object.freeze({}),
	) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(component: any) => {
		const { context, storeKey, forceV8, skipStability, ...restOptions } = options;
		const stabilizedMapping = skipStability
			? mapStateToProps
			: withStability(component, 'mapStateToProps', mapStateToProps);
		const stabilizedMergeProps = skipStability
			? mergeProps
			: withStability(component, 'mergeProps', mergeProps);
		const safeMapStateToProps = mapStateToPropsWithErrorHandling(stabilizedMapping);

		const ResultComponent = connect8(
			safeMapStateToProps,
			mapDispatchToProps,
			stabilizedMergeProps,
			{
				context,
				...restOptions,
			},
		)(component);
		ResultComponent.displayName = `Connect(${component.displayName || component.name || typeof component})`;
		return ResultComponent;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	}) as any;

// TODO: once all legacy context is removed from JFE, Provider5 can be fully removed. https://jplat.jira.atlassian.cloud/browse/MAGMA-2257
// subsequently, Provider5 can be removed from this file
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Provider8Wrapped = ({ store, children, context }: ProviderProps8<AnyAction, any>) => (
	<Provider5 store={store}>
		<Provider8 store={store} context={context}>
			{children}
		</Provider8>
	</Provider5>
);

/**
 * Enhances a component with feature flagging, enabling conditional rendering between
 * react-redux v5 and v8 Provider
 */
export const Provider = Provider8Wrapped;

const makeWrappedProvider8 =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(context: ProviderProps8['context']) => (props: ProviderProps8<AnyAction, any>) => (
		<Provider context={context} {...props} />
	);

export const createProvider = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	context: ProviderProps8<AnyAction, any>['context'],
) => makeWrappedProvider8(context);
